<template>
  <div class="Rules">
    <div class="Rules-contet">
      <div class="Rules-title">公海客户规则</div>
      <div class="Rules-word">
        <div class="Rules-word-item">
          <div>
            <span> <label>1、</label> 每人每天最多领取公海客户</span>
            <a-input
              style="width: 100px; text-align: center"
              v-model:value="value"
            /><span>个</span><label>( 包括领取跟分配 )</label>
          </div>
          <div>
            <span> <label>2、</label> 每人每天最多领取公海客户</span>
            <a-input style="width: 100px; text-align: center" /><span>
              个用户，则不能领取公海客户</span
            >
          </div>
          <div>
            <span> <label>3、</label>成交客户是否计算私海用户数量</span
            ><a-radio-group name="radioGroup">
              <a-radio value="1">是</a-radio>
              <a-radio value="2">否</a-radio>
            </a-radio-group>
          </div>
          <div>
            <span> <label>4、</label>用户领取同一客户不能超过</span>
            <a-input style="width: 100px; text-align: center" /><span>个</span>
          </div>
          <div>
            <span> <label>5、</label>客户从私海回到公海需超过</span>
            <a-input style="width: 100px; text-align: center" /><span
              >天后才能再次重新领取</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="Rules-contet">
      <div class="Rules-title">客户回收规则</div>
      <div class="Rules-word">
        <div class="Rules-word-item">
          <div>
            <span> <label>1、</label> 参与回收规则的客户类型 </span>
          </div>
          <div>
            <span> <label>2、</label> 获取后未跟进超过</span>
            <a-input style="width: 100px; text-align: center" /><span> 天</span>
          </div>
          <div>
            <span> <label>3、</label>成交客户是否计算私海用户数量</span
            ><a-radio-group name="radioGroup">
              <a-radio value="1">是</a-radio>
              <a-radio value="2">否</a-radio>
            </a-radio-group>
            <span> ( 同一个手机号码，不同坐席能否保存客户资料 )</span>
          </div>
          <div>
            <span> <label>3、</label>来电弹屏</span
            ><a-radio-group name="radioGroup2">
              <a-radio value="1">叠加</a-radio>
              <a-radio value="2">覆盖</a-radio>
            </a-radio-group>
          </div>
          <div>
            <span> <label>4、</label>点击拨号时间间隔</span>
            <a-input style="width: 100px; text-align: center" /><span>秒</span>
            <span>
              ( 默认0秒，此处为系统点击拨号自动自动拨号的时间间隔问题 )</span
            >
          </div>
          <div>
            <span> <label>5、</label>客户从私海回到公海需超过</span>
            <a-radio-group name="radioGroup3">
              <a-radio value="1">是</a-radio>
              <a-radio value="2">否</a-radio> </a-radio-group
            ><span>(来电号码匹配保存于客户资料中的坐席，如无匹配通话记录)</span>
          </div>
        </div>
      </div>
    </div>
    <a-button type="primary">保存</a-button>
  </div>
</template>

<script>
export default {
  name: "Rules",
};
</script>

<style>
.Rules {
  background-color: #fff;
  height: 100%;
  padding: 25px;
}
.Rules-contet {
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.Rules-title {
  font-size: 17px;
  margin-bottom: 20px;
  border-left: 4px solid #2990e5;
  padding-left: 8px;
  color: #2990e5;
}
.Rules-word-item {
  margin-left: 50px;
  font-size: 15px;
}
.Rules-word-item > div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.Rules-word-item div input {
  margin: 0 15px;
}
.Rules-word-item div span,
.Rules-word-item div label {
  display: inline-block;
}
.Rules-word-item > div > label {
  color: red;
  margin-left: 10px;
}
.ant-radio-group {
  margin-left: 15px;
}
</style>